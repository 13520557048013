import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Main from './pages/Main';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Main} />
      <Route
        path="/agparts"
        component={() => {
          window.location.href = 'https://i9p.app/agparts';
        }}
      />
      <Route
        path="/vitoria"
        component={() => {
          window.location.href = 'https://i9p.app/vitoria';
        }}
      />
      <Route
        path="/primavera"
        component={() => {
          window.location.href = 'https://i9p.app/primavera';
        }}
      />
      <Route
        path="/monalisa"
        component={() => {
          window.location.href = 'https://i9p.app/monalisa';
        }}
      />
      <Route
        path="/automais"
        component={() => {
          window.location.href = 'https://i9p.app/automais';
        }}
      />
      <Redirect to="/erro/404" />
    </Switch>
  );
}
