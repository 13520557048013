import React, { useEffect } from 'react';

import image from '../../../assets/Error/403.svg';

import Layout from '../components/Layout';

export default function Error401() {
  useEffect(() => {
    document.title = 'I9P - Erro 403';
  }, []);

  return (
    <Layout
      title="Acesso negado"
      text="Verifique se os dados de autenticação foram informados corretamente ou se possuem autorização de acesso."
      image={image}
      reload
    />
  );
}
