import styled from 'styled-components';

import { Accordion, Card } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 60px;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    margin-top: 80px;
  }
`;
export const FaqBox = styled.div`
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  margin-top: 30px;
  font-weight: 600;
  color: #57585a;
`;
export const Area = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  text-align: center;

  small {
    font-weight: 500;
    color: #57585a;
    font-size: 20px;
  }
`;
export const Questions = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  flex-direction: column;
`;

export const AccordionStyled = styled(Accordion)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-decoration: none;

  button {
    border: 0;
    &:focus {
      outline: none !important;
      outline-offset: none !important;
    }
  }
`;
export const CardStyled = styled(Card)`
  display: flex;
  height: 100%;
  flex-direction: column;

  .card-header {
    color: ${(props) =>
      props.active ? props.theme.colors.greenDark : '#494949'};
    font-weight: 600;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .card-body {
    font-weight: 500;
    color: #57585a;
    font-size: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    padding-left: 20px;
    padding-right: 20px;

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;
