import React, { useState } from 'react';
import swal from 'sweetalert';

import { Spinner } from 'react-bootstrap';
import api from '../../../../services/api';

import contatoPng from '../../../../assets/images/contato.png';
import contatoSvg from '../../../../assets/images/contato.svg';
import contatoWebp from '../../../../assets/images/contato.webp';

import {
  Container,
  ContactUs,
  Form,
  Input,
  Name,
  Phone,
  Email,
  Enterprise,
  Message,
  Image,
  ButtonArea,
  ContactButton,
} from './styles';

import SectionHeader from '../../../../components/SectionHeader';

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [enterprise, setEnterprise] = useState('');
  const [message, setMessage] = useState('');

  async function setContact(e) {
    e.preventDefault();
    setLoading(true);

    const data = { name, phone, email, enterprise, message };
    const response = await api.sendContact(data);

    setLoading(false);

    if (response?.status === 200)
      return swal({
        title: 'Recebemos seus dados :)',
        text:
          'Agradecemos o contato, entraremos em contato o mais breve possível.',
        icon: 'success',
      });

    return swal({
      title: 'Ops... :(',
      text:
        'Houve um erro não esperado ao enviar seus dados, tente novamente mais tarde.',
      icon: 'error',
    });
  }

  const PhoneMask = (text) => {
    return text
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d)/g, '($1) $2')
      .replace(/(\d)(\d)(\d{4})$/, '$1$2-$3');
  };

  function handlePhone(text) {
    setPhone(PhoneMask(text));
  }

  function handleName(text) {
    setName(text);
  }

  function handleEmail(text) {
    setEmail(text);
  }

  function handleEnterprise(text) {
    setEnterprise(text);
  }

  function handleMessage(text) {
    setMessage(text);
  }

  return (
    <Container id="contact">
      <SectionHeader
        title="Descubra como podemos ajudar a sua empresa"
        message="Entre em contato para entender como deixar seu negócio mais lucrativo"
      />
      <ContactUs>
        <Image>
          <picture>
            <source srcSet={contatoWebp} type="image/webp" />
            <source srcSet={contatoPng} type="image/png" />
            <source srcSet={contatoSvg} type="image/svg+xml" />
            <img src={contatoPng} alt="contato" />
          </picture>
        </Image>
        <Form onSubmit={(e) => setContact(e)}>
          <Name>
            <span>Nome:</span>
            <Input>
              <input
                required
                type="text"
                placeholder="Digite seu nome completo"
                value={name}
                onChange={(text) => handleName(text.target.value)}
              />
            </Input>
          </Name>
          <Phone>
            <span>Telefone:</span>

            <Input>
              <input
                required
                maxLength={15}
                minLength={15}
                type="text"
                placeholder="Digite seu telefone"
                value={phone}
                onChange={(text) => handlePhone(text.target.value)}
              />
            </Input>
          </Phone>
          <Email>
            <span>E-mail:</span>

            <Input>
              <input
                required
                type="email"
                placeholder="Digite seu e-mail"
                value={email}
                onChange={(text) => handleEmail(text.target.value)}
              />
            </Input>
          </Email>

          <Enterprise>
            <span>Empresa:</span>

            <Input>
              <input
                required
                type="text"
                placeholder="Digite o nome da sua empresa"
                value={enterprise}
                onChange={(text) => handleEnterprise(text.target.value)}
              />
            </Input>
          </Enterprise>

          <Message>
            <span>Assunto:</span>

            <Input>
              <textarea
                required
                type="text"
                placeholder="Conte-nos do que você precisa"
                value={message}
                onChange={(text) => handleMessage(text.target.value)}
              />
            </Input>
          </Message>
          <ButtonArea>
            <ContactButton disabled={loading} loading={loading}>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <span>Enviar</span>
              )}
            </ContactButton>
          </ButtonArea>
        </Form>
      </ContactUs>
    </Container>
  );
}
