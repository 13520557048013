import React from 'react';

import image from '../../../assets/Error/401.svg';
import Layout from '../components/Layout';

export default function Error401() {
  return (
    <Layout
      title="Autorização requerida"
      text=" Verifique se os dados de autenticação foram informados corretamente ou se possuem autorização de acesso."
      image={image}
      reload
    />
  );
}
