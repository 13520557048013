import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 60px;
  margin-bottom: 60px;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

export const ButtonContact = styled.button`
  display: flex;
  background: ${(props) => props.theme.colors.greenDark};
  width: 90%;
  min-height: 60px;
  border-radius: 10px;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  border: 0;
  color: #ffff;
  font-size: 20px;
  font-weight: 600;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    width: 40%;
  }

  &:focus {
    outline: none;
    outline-offset: none;
  }
`;
