import { createGlobalStyle } from 'styled-components';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  *{
    margin:0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
    font-family: 'Work Sans', sans-serif;
  }

  body {
    background: #FFFFFF;
    -webkit-font-smoothing: antialiased !important;
  }

  button {
    cursor:pointer;
  }
`;
