import React, { useEffect } from 'react';

import image from '../../../assets/Error/400.svg';
import Layout from '../components/Layout';

export default function Error401() {
  useEffect(() => {
    document.title = 'I9P - Erro 400';
  }, []);

  return (
    <Layout
      title="Falha na requisição"
      text=" Verifique a sintaxe do envio, pois o pedido não pôde ser entregue ao
    servidor."
      image={image}
      reload
    />
  );
}
