import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
`;

export const BoxRight = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column-reverse;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const BoxLeft = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Area = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 0;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex: 1;

  picture {
    width: 60%;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export const Title = styled.div`
  font-size: 26px;
  display: flex;
  margin: 20px 0;
  /* padding: 0 40px; */
  font-weight: 600;
  color: #494949;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  /* padding: 10px 30px; */
  /* margin: 10px; */
  text-align: justify;
  font-size: 20px;
  color: #57585a;
`;
