import styled from 'styled-components';

import { Jumbotron } from 'react-bootstrap';

export const Container = styled(Jumbotron)`
  display: flex;
  flex: 1;
  border-radius: 0;
  background: ${(props) => props.theme.colors.linearGradientGreen};
  padding-left: 5%;
  padding-right: 5%;
`;

export const TextArea = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const First = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #fff;

  strong {
    font-weight: 600;
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }

  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    font-size: 3rem;
    line-height: 3rem;
  }

  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    font-size: 4.5rem;
    line-height: 4.5rem;
  }
`;

export const Second = styled.h2`
  display: flex;
  color: #fff;
  width: auto;
  align-items: center;
  small {
    display: flex;
    font-size: 25px;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  picture {
    width: 60%;
    display: flex;
  }
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ButtonContact = styled.button`
  display: flex;
  background: #ffff;
  min-height: 60px;
  border-radius: 10px;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: 0;
  padding: 0 10px;
  color: #494949;
  font-size: 20px;
  font-weight: 600;

  &:focus {
    outline: none;
    outline-offset: none;
  }
`;
