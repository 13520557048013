import React from 'react';

import { Navbar, Nav } from 'react-bootstrap';

import logo from '../../assets/images/I9P-01.svg';
import { Container, NavLink, ButtonContact } from './styles';

export default function Header() {
  function handleScroll(id) {
    window.scrollTo({
      top: document.getElementById(id).offsetTop,
      behavior: 'smooth',
    });
  }

  return (
    <Container>
      <Navbar className="pl-0 pr-0" collapseOnSelect expand="md">
        <Navbar.Brand href="/">
          <img
            alt="Logo"
            src={logo}
            height="60"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="navbarCollpse">
          <Nav className="navbar-nav ml-auto">
            <NavLink
              className="ml-lg-4 mr-lg-4 ml-md-1 mr-md-1"
              onClick={() => handleScroll('solutions')}
            >
              Soluções
            </NavLink>
            <NavLink
              className="ml-lg-4 mr-lg-4 ml-md-1 mr-md-1"
              onClick={() => handleScroll('customers')}
            >
              Parceiros
            </NavLink>
            <NavLink
              className="ml-lg-4 mr-lg-4 ml-md-1 mr-md-1"
              onClick={() => handleScroll('contact')}
            >
              Contato
            </NavLink>
            <ButtonContact
              className="pl-2 pr-2 pl-lg-3 pr-lg-3"
              onClick={() => handleScroll('contact')}
            >
              Fale com um especialista
            </ButtonContact>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}
