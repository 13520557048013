import React from 'react';

import InfiniteCarousel from 'react-leaf-carousel';
import SectionHeader from '../../../../components/SectionHeader';

import agpartsPng from '../../../../assets/images/customers/agparts150.png';
import agpartsWebp from '../../../../assets/images/customers/agparts150.webp';
import monalisaPng from '../../../../assets/images/customers/monalisa150.png';
import monalisaWebp from '../../../../assets/images/customers/monalisa150.webp';
import primaveraPng from '../../../../assets/images/customers/primavera150.png';
import primaveraWebp from '../../../../assets/images/customers/primavera150.webp';
import vitoriaPng from '../../../../assets/images/customers/vitoria150.png';
import vitoriaWebp from '../../../../assets/images/customers/vitoria150.webp';
import cafedoamorPng from '../../../../assets/images/customers/cafedoamor150.png';
import cafedoamorWebp from '../../../../assets/images/customers/cafedoamor150.webp';

import { Container, Partners, Brand } from './styles';

export default function Customers() {
  return (
    <Container id="customers">
      <SectionHeader
        title="Quem já usa a I9P"
        message="Empresas que confiam em nossas soluções, faça parte desse time"
      />
      <Partners>
        <InfiniteCarousel
          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
          ]}
          dots={false}
          arrows={false}
          showSides
          sidesOpacity={1}
          sideSize={0.1}
          slidesToScroll={1}
          slidesToShow={4}
          autoCycle
          animationDuratio={500}
          slidesSpacing={90}
        >
          <Brand>
            <a href="https://i9p.app/agparts" target="_blank" rel="noreferrer">
              <picture>
                <source srcSet={agpartsWebp} type="image/webp" />
                <source srcSet={agpartsPng} type="image/png" />
                <img src={agpartsPng} alt="logo agparts" />
              </picture>
            </a>
          </Brand>
          <Brand>
            <a
              href="https://i9p.app/cafedoamor"
              target="_blank"
              rel="noreferrer"
            >
              <picture>
                <source srcSet={cafedoamorWebp} type="image/webp" />
                <source srcSet={cafedoamorPng} type="image/png" />
                <img src={cafedoamorPng} alt="logo cafe do amor" />
              </picture>
            </a>
          </Brand>
          <Brand>
            <a href="https://i9p.app/monalisa" target="_blank" rel="noreferrer">
              <picture>
                <source srcSet={monalisaWebp} type="image/webp" />
                <source srcSet={monalisaPng} type="image/png" />
                <img src={monalisaPng} alt="logo monalisa distribuidora" />
              </picture>
            </a>
          </Brand>
          <Brand>
            <a
              href="https://i9p.app/primavera"
              target="_blank"
              rel="noreferrer"
            >
              <picture>
                <source srcSet={primaveraWebp} type="image/webp" />
                <source srcSet={primaveraPng} type="image/png" />
                <img src={primaveraPng} alt="logo primavera alimentos" />
              </picture>
            </a>
          </Brand>
          <Brand>
            <a href="https://i9p.app/vitoria" target="_blank" rel="noreferrer">
              <picture>
                <source srcSet={vitoriaWebp} type="image/webp" />
                <source srcSet={vitoriaPng} type="image/png" />
                <img src={vitoriaPng} alt="logo vitoria hortifruti" />
              </picture>
            </a>
          </Brand>
        </InfiniteCarousel>
      </Partners>
    </Container>
  );
}
