import React from 'react';
import logoPng from '../../../../assets/images/logo-symbol.png';
import logoSvg from '../../../../assets/images/logo-symbol.svg';
import logoWebp from '../../../../assets/images/logo-symbol.webp';
import {
  Container,
  TextArea,
  First,
  Second,
  LogoArea,
  ButtonArea,
  ButtonContact,
} from './styles';

export default function Banner() {
  function handleScroll(id) {
    switch (id) {
      case 'contact':
        window.scrollTo({
          top: document.getElementById('contact').offsetTop,
          behavior: 'smooth',
        });
        break;

      default:
    }
  }

  return (
    <Container>
      <TextArea>
        <First>
          <span>
            <strong>I9P</strong>, seu pedido
          </span>
          <span>começa aqui.</span>
        </First>
        <Second className="mt-5">
          <small>Solução sob medida para o seu negócio.</small>
        </Second>
        <ButtonArea className="mt-5">
          <ButtonContact
            className="pl-4 pr-4 pl-md-5 pr-md-5"
            onClick={() => handleScroll('contact')}
          >
            <span>Fale com um especialista</span>
          </ButtonContact>
        </ButtonArea>
      </TextArea>
      <LogoArea className="d-none d-md-flex">
        <picture>
          <source srcSet={logoWebp} type="image/webp" />
          <source srcSet={logoPng} type="image/png" />
          <source srcSet={logoSvg} type="image/svg+xml" />
          <img src={logoPng} alt="I9P" />
        </picture>
      </LogoArea>
    </Container>
  );
}
