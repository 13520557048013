import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
`;

export const Illustration = styled.div`
  display: flex;
  margin-top: 60px;
  align-items: center;
  justify-content: center;
`;
export const Panel = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  color: #494949;
  font-weight: 600;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    font-size: 20px;
  }

  strong {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    color: #494949;
    font-weight: 600;
  }

  picture {
    width: 33%;
  }

  img {
    width: 100%;
    height: auto;
  }
`;
export const FreeTax = styled(Panel)`
  border-left: 2px solid #dfdfdf;
`;
export const SelfMobile = styled(Panel)`
  border-left: 2px solid #dfdfdf;
`;
