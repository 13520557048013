import React, { useState } from 'react';

import {
  Container,
  FaqBox,
  Box,
  Title,
  Area,
  Questions,
  AccordionStyled,
  CardStyled,
} from './styles';

export default function Faq() {
  const [activeIndex, setActiveIndex] = useState('1');

  return (
    <Container>
      <FaqBox>
        <Box>
          <Title>Dúvidas frequentes</Title>
          <Area>
            <small>
              Aqui estão algumas perguntas dos nossos usuários que podem ser
              úteis
            </small>
          </Area>
          <Questions>
            <AccordionStyled
              defaultActiveKey={activeIndex}
              onSelect={(index) => {
                setActiveIndex(index);
              }}
            >
              <AccordionStyled.Toggle variant="" eventKey="1">
                <CardStyled active={activeIndex === '1'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>1. Como posso testar a I9P no meu negócio ?</span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="1">
                    <CardStyled.Body>
                      É só entrar em contato com os nossos especialistas e
                      solicitar sua versão de teste! :)
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="2">
                <CardStyled active={activeIndex === '2'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>2. Para quem a solução i9P é indicada ?</span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="2">
                    <CardStyled.Body>
                      Para todos os negócios que querem simplificar o processo
                      de venda, conquistar novos clientes e aumentar o volume de
                      vendas.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="3">
                <CardStyled active={activeIndex === '3'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>
                        3. Quais são as formas de precificação ao usar as
                        soluções da I9P ?
                      </span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="3">
                    <CardStyled.Body>
                      As soluções I9P possuem uma margem percentual de acordo
                      com o volume de venda através da plataforma. Entre em
                      contato para agendarmos uma apresentação.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="4">
                <CardStyled active={activeIndex === '4'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>
                        4. Consigo configurar a minha região de atendimento ?
                      </span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="4">
                    <CardStyled.Body>
                      Sim, utilizando nosso módulo de delivery você pode definir
                      a área que deseja atender com taxas personalizadas.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="5">
                <CardStyled active={activeIndex === '5'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>
                        5. Consigo vender meus produtos com preços definido para
                        cada cliente ?
                      </span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="5">
                    <CardStyled.Body>
                      Sim, na nossa plataforma você pode definir uma tabela de
                      preço específica para cada cliente.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="6">
                <CardStyled active={activeIndex === '6'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>
                        6. Consigo expor meus produtos exibindo preços apenas
                        para clientes cadastrados ?
                      </span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="6">
                    <CardStyled.Body>
                      Sim, na nossa plataforma você pode definir se deseja ter
                      uma tabela de preço pública ou fechada.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="7">
                <CardStyled active={activeIndex === '7'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>
                        7. Consigo apenas expor meus produtos, sem opção de
                        venda ?
                      </span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="7">
                    <CardStyled.Body>
                      Sim, para isso você pode utilizar nosso modulo de vitrine.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="8">
                <CardStyled active={activeIndex === '8'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>
                        8. Não possuo sistema de gestão na minha empresa.
                        Consigo utilizar a plataforma da I9P ?
                      </span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="8">
                    <CardStyled.Body>
                      Sim, possuímos um painel administrativo onde podem ser
                      feito todos os cadastros e configurações necessárias. Mas
                      também podemos oferecer um sistema de gestão parceiro,
                      entre em contato que te auxiliamos.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
              <AccordionStyled.Toggle variant="" eventKey="9">
                <CardStyled active={activeIndex === '9'}>
                  <CardStyled.Header className="bg-white">
                    <Area>
                      <span>
                        9. Já possuo um ERP implantado. Consigo usar a
                        plataforma para vender meus produtos? ?
                      </span>
                    </Area>
                  </CardStyled.Header>
                  <AccordionStyled.Collapse eventKey="9">
                    <CardStyled.Body>
                      A nossa plataforma pode se integrar a qualquer ERP através
                      de API desenvolvida pela I9P.
                    </CardStyled.Body>
                  </AccordionStyled.Collapse>
                </CardStyled>
              </AccordionStyled.Toggle>
            </AccordionStyled>
          </Questions>
        </Box>
      </FaqBox>
    </Container>
  );
}
