import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 60px;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    margin-top: 80px;
  }
`;
export const ContactUs = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  margin-top: 60px;
  flex-direction: column;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex: 1;

  picture {
    width: 80%;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
  }
`;

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* padding: 20px 0; */
`;

export const Input = styled.div`
  display: flex;
  justify-content: flex-end;

  input {
    flex: 1;
    font-size: 15px;
    background-color: transparent;
    color: #57585a;
    border: 1px solid #bcbcbc;
    border-radius: 0.25rem;
    transition: border 0.6s;
    padding: 10px;
    margin: 10px;

    &:focus {
      border: 1px solid #57585a;
    }
  }

  textarea {
    flex: 1;
    font-size: 15px;
    background-color: transparent;
    color: #57585a;
    border: 1px solid #bcbcbc;
    border-radius: 0.25rem;
    transition: border 0.6s;
    padding: 10px;
    margin: 10px;

    &:focus {
      border: 1px solid #57585a;
    }
  }
`;

export const Name = styled.div`
  padding: 0 20px;
  color: #57585a;

  span {
    margin: 10px;
    font-size: 15px;
    color: #57585a;
    font-weight: 600;
  }
`;
export const Phone = styled(Name)``;
export const Email = styled(Name)``;
export const Enterprise = styled(Name)``;
export const Message = styled(Name)``;

export const ButtonArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  justify-content: flex-end;
`;
export const ContactButton = styled('button')`
  display: flex;
  background: ${(props) => props.theme.colors.greenDark};
  cursor: ${(props) => (props.loading ? 'wait' : 'pointer')};
  height: 45px;
  width: 30%;
  border-radius: 10px;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 0;
  padding: 0 10px;

  span {
    color: #ffff;
  }
  &:focus {
    outline: none;
    outline-offset: none;
  }
`;
