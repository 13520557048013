import React, { useEffect } from 'react';

import image from '../../../assets/Error/404.svg';

import Layout from '../components/Layout';

export default function Error404() {
  useEffect(() => {
    document.title = 'I9P - Erro 404';
  }, []);

  return (
    <Layout
      title="Pagina não encontrada"
      text="Verifique se a URL da página foi informada corretamente ou se foi digitada sem erros."
      image={image}
      reload
    />
  );
}
