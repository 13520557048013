import styled from 'styled-components';

export const Button = styled.a`
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 15px;
  right: 15px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    background-color: #21bd5b;
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 30px;
  }
`;
