import React from 'react';
import painelPng from '../../../../assets/images/painel.png';
import painelSvg from '../../../../assets/images/painel.svg';
import painelWebp from '../../../../assets/images/painel.webp';
import taxaPng from '../../../../assets/images/taxa.png';
import taxaSvg from '../../../../assets/images/taxa.svg';
import taxaWebp from '../../../../assets/images/taxa.webp';
import appPng from '../../../../assets/images/app.png';
import appSvg from '../../../../assets/images/app.svg';
import appWebp from '../../../../assets/images/app.webp';

import { Container, Illustration, Panel, FreeTax, SelfMobile } from './styles';

import SectionHeader from '../../../../components/SectionHeader';
import ButtonContactGreen from '../../../../components/ButtonContactGreen';

export default function Advantage() {
  return (
    <Container id="integration">
      <SectionHeader
        title="Soluções que agregam ao seu negócio"
        message="Facilidade para tornar sua empresa ainda mais ágil"
      />
      <Illustration>
        <Panel className="col-4">
          <picture>
            <source srcSet={painelWebp} type="image/webp" />
            <source srcSet={painelPng} type="image/png" />
            <source srcSet={painelSvg} type="image/svg+xml" />
            <img src={painelPng} alt="Painel adm" />
          </picture>
          <strong>Painel administrativo intuitivo</strong>
        </Panel>
        <FreeTax className="col-4">
          <picture>
            <source srcSet={taxaWebp} type="image/webp" />
            <source srcSet={taxaPng} type="image/png" />
            <source srcSet={taxaSvg} type="image/svg+xml" />
            <img src={taxaPng} alt="sem taxas" />
          </picture>
          <strong>Livre de taxas abusivas</strong>
        </FreeTax>
        <SelfMobile className="col-4">
          <picture>
            <source srcSet={appWebp} type="image/webp" />
            <source srcSet={appPng} type="image/png" />
            <source srcSet={appSvg} type="image/svg+xml" />
            <img src={appPng} alt="aplicativo próprio" />
          </picture>
          <strong>Aplicativo próprio da sua loja </strong>
        </SelfMobile>
      </Illustration>
      <ButtonContactGreen />
    </Container>
  );
}
