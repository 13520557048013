import React from 'react';
import Advantage from './components/Advantages';
import Banner from './components/Banner';
// import Cases from './components/Cases';
import Contact from './components/Contact';
import Customers from './components/Customers';
import Faq from './components/Faq';
import Solutions from './components/Solutions';
import ButtonWhatsapp from '../../components/ButtonWhatsapp';

import { Container } from './styles';

export default function Main() {
  return (
    <Container>
      <Banner />
      <Solutions />
      <Advantage />
      {/* <Cases /> */}
      <Customers />
      <Contact />
      <Faq />
      <ButtonWhatsapp />
    </Container>
  );
}
