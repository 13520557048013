import React from 'react';
import { Switch, Route, Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { Container } from 'react-bootstrap';
import { theme } from './styles/theme';

import Header from './components/Header';
import Footer from './components/Footer';

import Routes from './routes';
import history from './services/history';

import GlobalStyle from './styles/global';

import Error400 from './pages/Error/400';
import Error401 from './pages/Error/401';
import Error403 from './pages/Error/403';
import Error404 from './pages/Error/404';
import Error500 from './pages/Error/500';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          <Route exact path="/erro/400" component={Error400} />
          <Route exact path="/erro/401" component={Error401} />
          <Route exact path="/erro/403" component={Error403} />
          <Route exact path="/erro/404" component={Error404} />
          <Route exact path="/erro/500" component={Error500} />
          <Container className="p-0" fluid>
            <Header />
            <Routes />
            <Footer />
          </Container>
        </Switch>
        <GlobalStyle />
      </Router>
    </ThemeProvider>
  );
}

export default App;
