import React from 'react';

import { FaWhatsapp } from 'react-icons/fa';

import { Button } from './styles';

function ButtonWhatsapp() {
  return (
    <Button
      href="https://wa.me/5515997485154?text=Olá, tenho interesse na plataforma da I9P, gostaria de mais informações. "
      target="_blank"
      rel="noopener noreferrer"
      alt="Whatsapp"
    >
      <FaWhatsapp alt="Whatsapp" style={{ flex: '1', color: '#fff' }} />
    </Button>
  );
}

export default ButtonWhatsapp;
