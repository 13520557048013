import axios from 'axios';

const api = axios.create({
  baseURL: 'https://www.api.i9p.com.br/api/v1',
});

export default {
  async sendContact(data) {
    const response = await api.post('/contact', data).then(null, (error) => {
      return error;
    });

    return response;
  },
};
