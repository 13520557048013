import React from 'react';

import { GrInstagram, GrFacebook, GrLinkedin } from 'react-icons/gr';
import { AiOutlineCopyright } from 'react-icons/ai';

import I9P from '../../assets/images/I9P-01.svg';

import {
  Container,
  Area,
  Image,
  LinksArea,
  Icons,
  Links,
  NavStyled,
  Copyright,
} from './styles';

export default function Footer() {
  function handleScroll(id) {
    switch (id) {
      case 'solutions':
        window.scrollTo({
          top: document.getElementById('solutions').offsetTop,
          behavior: 'smooth',
        });
        break;
      case 'contact':
        window.scrollTo({
          top: document.getElementById('contact').offsetTop,
          behavior: 'smooth',
        });
        break;

      case 'customers':
        window.scrollTo({
          top: document.getElementById('customers').offsetTop,
          behavior: 'smooth',
        });
        break;
      default:
    }
  }
  return (
    <Container>
      <Area>
        <Image>
          <img src={I9P} alt="Logo" />
        </Image>
        <LinksArea>
          <Icons>
            <a
              href="https://facebook.com/I9pTecnologia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GrFacebook />
            </a>
            <a
              href="https://www.instagram.com/I9pTecnologia"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GrInstagram />
            </a>
            <a
              href="https://www.linkedin.com/company/i9p"
              target="_blank"
              rel="noopener noreferrer"
            >
              <GrLinkedin />
            </a>
          </Icons>
          <Links>
            <NavStyled.Link onClick={() => handleScroll('solutions')}>
              <span>A solução</span>
            </NavStyled.Link>
            <NavStyled.Link onClick={() => handleScroll('customers')}>
              <span>Parceiros</span>
            </NavStyled.Link>
            <NavStyled.Link onClick={() => handleScroll('contact')}>
              <span>Contato</span>
            </NavStyled.Link>
            <NavStyled.Link
              href="https://painel.i9p.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Área do cliente</span>
            </NavStyled.Link>
          </Links>
        </LinksArea>
      </Area>
      <Copyright className="pt-4 pb-4">
        <span>
          Copyright <AiOutlineCopyright /> 2020. Todos os direitos reservados a
          I9P.
        </span>
      </Copyright>
    </Container>
  );
}
