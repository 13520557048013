import React from 'react';

import { Container, ButtonContact } from './styles';

export default function ButtonContactGreen() {
  function handleScroll(id) {
    switch (id) {
      case 'contact':
        window.scrollTo({
          top: document.getElementById('contact').offsetTop,
          behavior: 'smooth',
        });
        break;

      default:
    }
  }

  return (
    <Container>
      <ButtonContact onClick={() => handleScroll('contact')}>
        <span>Fale com um especialista</span>
      </ButtonContact>
    </Container>
  );
}
