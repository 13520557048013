import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 26px;
  color: #494949;
  font-weight: 600;
`;

export const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: #57585a;

  small {
    font-weight: 500;
    font-size: 20px;
    color: #57585a;
  }
`;
