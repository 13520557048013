import styled from 'styled-components';

import { Nav } from 'react-bootstrap';

export const Container = styled.div`
  display: flex;
  width: auto;
  height: auto;
  background: #dddddd;
  flex-direction: column;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 60px;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    margin-top: 80px;
  }
`;

export const Area = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Image = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  align-items: center;
  justify-content: center;
  /* margin-left: 60px; */

  img {
    display: flex;
    width: 50%;
    height: auto;
    margin-top: 30px;

    /* Medium devices (tablets, 768px and up) */
    @media (min-width: 768px) {
      width: 40%;
    }
  }

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;

export const LinksArea = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  flex-direction: row-reverse;

  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 150px;
  justify-content: center;
  align-items: center;

  svg {
    display: flex;
    height: 30px;
    width: 35px;
    color: #4b4b4b;
    margin: 0 10px;
    transition: filter 0.2s;

    &:hover {
      color: #393939;
      filter: brightness(0.8);
    }
  }
`;
export const Links = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  flex: 1;

  span {
    color: #454545;
    transition: filter 0.2s;

    &:hover {
      color: #000;
      filter: brightness(0.8);
    }
  }
`;

export const NavStyled = styled(Nav)`
  display: flex;
  color: #fff;
  font-size: 30px;
  background: #fff;
`;

export const Copyright = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #454545;
`;
