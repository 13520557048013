import React from 'react';
import b2bPng from '../../../../assets/images/b2b.png';
import b2bSvg from '../../../../assets/images/b2b.svg';
import b2bWebp from '../../../../assets/images/b2b.webp';
import catalogoPng from '../../../../assets/images/catalogo.png';
import catalogoSvg from '../../../../assets/images/catalogo.svg';
import catalogoWebp from '../../../../assets/images/catalogo.webp';
import i9pPng from '../../../../assets/images/i9p.png';
import i9pWebp from '../../../../assets/images/i9p.webp';
import deliveryPng from '../../../../assets/images/delivery.png';
import deliverySvg from '../../../../assets/images/delivery.svg';
import deliveryWebp from '../../../../assets/images/delivery.webp';

import {
  Container,
  BoxRight,
  BoxLeft,
  Title,
  Message,
  Image,
  Area,
} from './styles';

import ButtonContactGreen from '../../../../components/ButtonContactGreen';

export default function Solutions() {
  return (
    <Container id="solutions">
      <BoxRight>
        <Area>
          <Title>Plataforma B2B</Title>
          <Message>
            <span>
              Automatize o seu processo de entrada de pedidos com velocidade,
              atingindo clientes geograficamente distantes, com tecnologia e
              facilidade. A nossa solução proporciona um canal de vendas direto
              com o seu cliente, sem precisar da mediação de um vendedor.
            </span>
          </Message>
        </Area>
        <Image>
          <picture>
            <source srcSet={b2bWebp} type="image/webp" />
            <source srcSet={b2bPng} type="image/png" />
            <source srcSet={b2bSvg} type="image/svg+xml" />
            <img src={b2bPng} alt="b2b" />
          </picture>
        </Image>
      </BoxRight>

      <BoxLeft>
        <Image>
          <picture>
            <source srcSet={deliveryWebp} type="image/webp" />
            <source srcSet={deliveryPng} type="image/png" />
            <source srcSet={deliverySvg} type="image/svg+xml" />
            <img src={deliveryPng} alt="delivery" />
          </picture>
        </Image>

        <Area>
          <Title className="justify-content-end"> Plataforma Delivery </Title>
          <Message className="justify-content-end">
            <span>
              Se o seu estabelecimento trabalha com delivery, nós temos a
              solução ideal para você. Tenha seu próprio site e aplicativo com
              controle total sobre os pedidos, sem pagar taxas ou valores
              abusivos. Gerencie suas entregas com facilidade e processos
              simplificados.
            </span>
          </Message>
        </Area>
      </BoxLeft>

      <BoxRight>
        <Area>
          <Title> Catálogo Digital</Title>
          <Message>
            <span>
              Se você precisa disponibilizar os seus produtos para o seu cliente
              escolher a melhor opção e solicitar um orçamento, essa é a solução
              ideal. Com a nossa plataforma você transforma o processo da
              divulgação de uma maneira mais simples.
            </span>
          </Message>
        </Area>
        <Image>
          <picture>
            <source srcSet={catalogoWebp} type="image/webp" />
            <source srcSet={catalogoPng} type="image/png" />
            <source srcSet={catalogoSvg} type="image/svg+xml" />
            <img src={catalogoPng} alt="Catálogo Digital" />
          </picture>
        </Image>
      </BoxRight>

      <BoxLeft>
        <Image>
          <picture>
            <source srcSet={i9pWebp} type="image/webp" />
            <source srcSet={i9pPng} type="image/png" />
            <img src={i9pPng} alt="vantagens" />
          </picture>
        </Image>
        <Area>
          <Title className="justify-content-end"> A I9P te oferece mais!</Title>
          <Message className="justify-content-end">
            <span>
              Todas as nossas soluções contam com um painel administrativo para
              você acompanhar os pedidos, cadastrar produtos e acompanhar suas
              vendas!
            </span>
          </Message>
        </Area>
      </BoxLeft>
      <ButtonContactGreen />
    </Container>
  );
}
