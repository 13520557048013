import React from 'react';

import { Container, Title, Message } from './styles';

export default function SectionHeader({ title, message }) {
  return (
    <Container>
      <Title>
        <span>{title}</span>
      </Title>
      <Message>
        <small>{message}</small>
      </Message>
    </Container>
  );
}
