import styled from 'styled-components';

import { Button, Nav } from 'react-bootstrap';

export const Container = styled.div`
  padding-left: 5%;
  padding-right: 5%;
`;

export const NavLink = styled(Nav.Link)`
  font-weight: 500;
  color: #3c3c3c;
  font-size: 20px;
`;

export const ButtonContact = styled(Button)`
  background: ${(props) => props.theme.colors.greenDark};
  border-color: ${(props) => props.theme.colors.greenDark};
  border-radius: 15px;
  min-height: 40px;
  font-weight: 600;
  color: #fff;
  font-size: 20px;

  &:focus {
    outline: none;
    outline-offset: none;
  }

  &:hover {
    background: ${(props) => props.theme.colors.greenDark};
    border-color: ${(props) => props.theme.colors.greenDark};
  }
`;
